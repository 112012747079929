import React, { useEffect, useRef } from 'react'
import './intro.scss'
import { init } from 'ityped'
import {Person,Mail} from '@mui/icons-material'

export default function Intro() {
  const textRef = useRef();
  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,
      strings: ["Full-Stack", "Front-End", "Kargin"]
    })
  },[]);
  return (
    <div className='intro' id='intro'>
      <div className="left">
        <div className="imgContainer">
          <img src="assets/transparent_me.png" alt="man" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi there, I'm</h2>
          <h1>Vahan Sardaryan</h1>
          <h3>
            <span ref={textRef}></span> developer
          </h3>
        </div>
        <div className='contact-info'>
          <div className="itemContainer">
            <Person className="icon"/>
            <span>+374 41 599 010</span>
          </div>
          <div className="itemContainer">
            <Mail className="icon"/>
            <span>vahanvs2002@gmail.com</span>
          </div>
        </div>
        <a href="#portfolio">
          <img src="assets/down.png" alt="down" />
        </a>
      </div>
    </div>
  )
}
