import React, { useState } from 'react'
import './contact.scss'
import axios from 'axios';

export default function Contact() {
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.length === 0) {
      alert("Email is blank!");
    } else if (textMessage.length === 0) {
      alert("Message is blank!");
    } else {
      const url = "https://xn--y9aaaaaq5fzbff5eh6ac.xn--y9a3aq/main.php";
      let fData = new FormData();
      fData.append('email', email);
      console.log(email);
      fData.append('message', textMessage);
      // axios.post(url, fData).then(response => {
      //   console.log(response.data)
      // }).catch(error => console.log(error));
      try {
        const response = await axios.post(url, fData);
        console.log(response.data);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
      setMessage(true);
    }
  }
    return (
      <div className='contact' id='contact'>
        <div className="left">
          <img src="assets/shake.svg" alt="shake" />
        </div>
        <div className="right">
          <h2>Contact.</h2>
          <form onSubmit={handleSubmit}>
            <input type="text" name="email" placeholder='Email or Phone' value={email} onChange={(e)=>setEmail(e.target.value)} />
            <textarea name="message" placeholder='Message'value={textMessage} onChange={(e)=>setTextMessage(e.target.value)}></textarea>
            <button type='submit' name='submit' >Send</button>
            {message && <span>Thanks, I'll reply ASAP</span>}
          </form>
        </div>
      </div>
    );
}
